import { Link, useNavigate } from "react-router-dom";
import useScroll from "../../Hooks/useScroll";
import PopCart from "../PopCart/PopCart";
import logo from "../../Images/earth-beauty-you-logo.png";
import SearchedProducts from "../SearchedProducts/SearchedProducts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import Cart from "../Cart/Cart";
import { useState } from "react";
import { useContext } from "react";
import { ThemeContext } from "../../Contexts/ThemeContext";
import AllProducts from "../NavCategories/AllProducts";
const LargeCategory = ({ popCart, handlePopCart, setCategory }) => {
  const { products } = useContext(ThemeContext);
  const otpUser = JSON.parse(localStorage.getItem("user"));
  const [focus, setFocus] = useState(false);
  const { searchText, setSearchText } = useContext(ThemeContext);
  const [scrollPosition] = useScroll();
  let highPriorityProducts = [];
  let searchedProducts = [];
  const navigation = useNavigate();

  if (searchText.length > 2) {
    searchedProducts = products?.filter((product) =>
      product.name.toLowerCase().includes(searchText?.toLowerCase())
    );
    highPriorityProducts = [];
  }

  return (
    <div>
      <div className="bg-accent flex justify-center py-1 text-primary font-bold hidden lg:flex">
        Get Free Delivery For BDT 999+
      </div>
      <div
        className={`nav-menu relative hidden lg:flex transition-all ease-in-out duration-300 text-center py-10 text-white nav-menu-scrolled border border-secondary border-0 border-b-[1px]`}
      >
        <PopCart handlePopCart={handlePopCart} popCart={popCart}></PopCart>

        {/*----------- Nav Logo  -----------------*/}

        <Link>
          <img
            className="ms-10 w-[100px] absolute top-[6px] left-0"
            src={logo}
            alt=""
            width={334}
            height={201}
            title="Earth Beauty & You"
          />
        </Link>

        {/*------------------------------------- All Navigation Category  ------------------------------------*/}

        <div className="all-menu">
          <AllProducts />
          <Link
            className={`mr-10 block font-bold ${
              scrollPosition > 80
                ? "hover:text-accent text-secondary"
                : "text-secondary hover:text-accent"
            }`}
            to={`/newarrivals`}
          >
            NEW ARRIVALS
          </Link>

          {/*-------------- Blogs ----------------*/}
          <Link
            to="/blogs"
            className={`mr-10 block font-bold ${
              scrollPosition > 80
                ? "hover:text-accent text-secondary"
                : "text-secondary hover:text-accent"
            }`}
          >
            BLOGS
          </Link>
          {/*----------------- Vegan  --------------------*/}
          <Link
            className={`mr-10 block font-bold ${
              scrollPosition > 80
                ? "hover:text-accent text-secondary"
                : "text-secondary hover:text-accent"
            }`}
            to={`/about`}
          >
            ABOUT US
          </Link>
        </div>
        <div className="form-control text-black 2xl:w-[30%] lg:w-[20%] relative">
          <form
            className="input-group"
            onSubmit={(e) => {
              e.preventDefault();
              navigation(`/search/${searchText}`);
              setFocus(false);
            }}
          >
            <input
              type="text"
              className="border border-r-0 border-[#cccccc] w-[100%] px-4"
              placeholder="Search in earth beauty & You"
              onFocus={() => setFocus(true)}
              onBlur={() => {
                setTimeout(() => setFocus(false), 200);
              }}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <button
              type="submit"
              className="btn btn-rounded bg-primary border border-l-0 border-[#cccccc]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 hover:text-primary"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </button>
          </form>

          {/*......... Search result............ */}
          <div>
            <SearchedProducts
              highPriorityProducts={highPriorityProducts}
              searchedProducts={searchedProducts}
              focus={focus}
            />
          </div>
        </div>
        <a href="https://luvit.com.bd" target="--blank">
          <img
            className="w-[60px] ml-10"
            src="https://luvit.com.bd/wp-content/uploads/2022/11/Luvit-final-Logo.svg"
            alt=""
            width={200}
            height={100}
            title="Luvit"
          />
        </a>
        <div>
          <div
            className={` cart-icon mobile-cart-position top-[55px] right-[40px]`}
          >
            {otpUser?.phone ? (
              <Link to="/dashboard" className="hover:text-accent">
                <FontAwesomeIcon
                  className="text-[24px] mr-10 mb-2"
                  icon={faUser}
                ></FontAwesomeIcon>
              </Link>
            ) : (
              <Link to="/customerDashboard" className="hover:text-accent">
                <FontAwesomeIcon
                  className="text-[24px] mr-10 mb-2"
                  icon={faUser}
                ></FontAwesomeIcon>
              </Link>
            )}
          </div>
          <Cart popCart={popCart} handlePopCart={handlePopCart}></Cart>
        </div>
      </div>
    </div>
  );
};

export default LargeCategory;
