import React, { useContext, useEffect } from "react";
import "./MobileCategory.css";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { ThemeContext } from "../../Contexts/ThemeContext";
import MobileAllProducts from "./MobileCatefories/MobileAllProducts";

const MobileCategory = ({ menu, handleMenu }) => {
  const { setCategory } = useContext(ThemeContext);
  const location = useLocation();

  useEffect(() => {
    handleMenu(false);
  }, [location.pathname]);


  return (
    <div
      className={`mobile-category-container ${
        menu ? "" : "mobile-category-container-left"
      }`}
    >
      {/*------------------------------------- All Navigation Category  ------------------------------------*/}

      <FontAwesomeIcon
        icon={faXmark}
        onClick={() => handleMenu(false)}
        className={` block ml-auto w-[24px] h-[24px] m-4 text-accent`}
      />
      <div className="mobile-category">
        <MobileAllProducts />
        <br />

        {/*------------------ new arrivals  ----------------*/}
        <Link
          className={`mr-10 block font-bold `}
          to={`/newarrivals`}
        >
          NEW ARRIVALS
        </Link>
        <br />

        {/*-------------- Blogs ----------------*/}
        <Link to="/blogs" className={`mr-10 block font-bold `}>BLOGS</Link>
        <br />
        <Link to="/about" className={`mr-10 block font-bold `}>ABOUT US</Link>
        <br />
   
        <a href="https://luvit.com.bd" target="__blank">
          <img
            className="w-[60px]"
            src="https://luvit.com.bd/wp-content/uploads/2022/11/Luvit-final-Logo.svg"
            alt=""
            width={200}
            height={100}
            title="Luvit"
          />
        </a>
      </div>
    </div>
  );
};

export default MobileCategory;
