import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";

const CouponList = () => {
  const [coupons, setCoupons] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [loading, setLoading] = useState(false);
  const [deletedId, setDeletedId] = useState();

  useEffect(() => {
    setLoading(true);
    fetch(`https://ebay-backend.vercel.app/getAllCoupons`)
      .then((res) => res.json())
      .then((data) => {
        setCoupons(data);
        setLoading(false);
      });
  }, []);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleDelete = (id) => {
    fetch(`https://ebay-backend.vercel.app/deleteCoupon/${id}`, {
      method: "delete",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
    toast.success("Coupon deleted sucessfully");
    const rest = coupons.filter((coupon) => coupon._id !== id);
    setCoupons(rest);
    console.log(id, "deleted");
  };

  const handleSearch = () => {
    const searchedCoupon = coupons.filter((coupon) =>
      coupon.code.toLowerCase().includes(searchedText.toLowerCase())
    );
    setCoupons(searchedCoupon);
  };

  return (
    <div>
      <div className="flex justify-between mx-12">
        <div className="flex">
          <h1 className="mt-12 mr-12 text-2xl">Coupons</h1>
          <Link
            to="/admin/addCoupon"
            className="px-6 mt-12 mr-6 border flex justify-center items-center border-accent bg-primary text-accent hover:bg-accent hover:text-primary ease-in-out duration-300 "
          >
            Add Coupon
          </Link>
        </div>
        <div>
          <input
            type="text"
            placeholder="Search..."
            className="border border-secondary px-4 py-1 border-opacity-40"
            onChange={(e) => setSearchedText(e.target.value)}
            value={searchedText}
          ></input>
          <button
            onClick={handleSearch}
            className="px-6 mt-12 ml-4 py-1 border border-accent bg-primary text-accent hover:bg-accent hover:text-primary ease-in-out duration-300 "
          >
            Search
          </button>
        </div>
      </div>

      {coupons.length < 1 && loading ? (
        <div className="flex justify-center items-center h-[90vh]">
          <ThreeDots
            height="100"
            width="100"
            radius="10"
            color="#f34b7f"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="product-table">
            {/* head */}
            <thead className="bg-accent text-primary">
              <tr>
                <th></th>
                <th>Code</th>
                <th>Coupon type</th>
                <th>Coupon amount</th>
                <th>Description</th>
                <th>Usage</th>
                <th>Expiry date</th>
                <th>Used in orders</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {/*................table row............... */}
              {coupons?.map((coupons) => (
                <tr className="">
                  <th>
                    <label>
                      <input type="checkbox" className="checkbox" />
                    </label>
                  </th>
                  <td className="w-[20%]">
                    <div className="flex items-center space-x-3">
                      <div>
                        <p className="font-bold">{coupons?.code} -</p>
                      </div>
                      <div>
                        <p className="font-bold">{coupons?.status}</p>
                      </div>
                    </div>
                  </td>
                  <td>{coupons?.discount_type}</td>
                  <td>{coupons?.amount}</td>
                  <td>{coupons?.description}</td>
                  <td>{coupons?.usage_count}</td>
                  <td>
                    {months[new Date(coupons?.date_expires).getMonth()]}{" "}
                    {new Date(coupons?.date_expires).getDate()},{" "}
                    {new Date(coupons?.date_expires).getFullYear()}
                  </td>
                  <td>View</td>
                  <td>
                    <Link
                      to={`/admin/editCoupon/${coupons._id}`}
                      className="mr-4"
                    >
                      <FontAwesomeIcon
                        className="text-[#8ed03a]"
                        icon={faPenToSquare}
                      ></FontAwesomeIcon>
                    </Link>
                    <Link
                      onClick={() => {
                        setDeletedId(coupons._id);
                        document.getElementById("confirmation").showModal();
                      }}
                    >
                      <FontAwesomeIcon
                        className="text-[#b14444]"
                        icon={faTrash}
                      ></FontAwesomeIcon>
                    </Link>
                    <dialog
                      id="confirmation"
                      className="bg-accent rounded p-10"
                    >
                      <form method="dialog" className="">
                        <button className="btn text-primary btn-sm btn-circle btn-ghost absolute right-2 top-2">
                          ✕
                        </button>
                        <h3 className="font-bold text-lg text-primary">
                          Confirm Delete !
                        </h3>
                        <p className="py-4 text-primary">
                          Are you sure you want to delete this item ?
                        </p>
                        <button
                          onClick={() => handleDelete(deletedId)}
                          className="btn py-3 bg-primary text-accent border-none"
                        >
                          Confirm
                        </button>
                      </form>
                    </dialog>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* foot */}
            <tfoot className="bg-accent text-primary">
              <tr>
                <th></th>
                <th>Code</th>
                <th>Coupon type</th>
                <th>Coupon amount</th>
                <th>Description</th>
                <th>Usage</th>
                <th>Expiry date</th>
                <th>Used in orders</th>
                <th>Actions</th>
              </tr>
            </tfoot>
          </table>
        </div>
      )}
    </div>
  );
};

export default CouponList;

//npm install react react-dom react-modal
