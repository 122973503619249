import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import acne from "../../Images/acne.webp";
import haircare from "../../Images/hair care.webp";
import dullSKin from "../../Images/Dull Skin.webp";
import tan from "../../Images/Tanning (1).webp";
import oily from "../../Images/Oily skin.webp";
import dry from "../../Images/Dry skin.webp";
import aging from "../../Images/aging.webp";
import spot from "../../Images/spot.jpg";
import "./ShopByConcern.css"; // Import the CSS file
import { Link } from "react-router-dom";

const ShopByConcern = () => {
  const [isDragging, setIsDragging] = useState(false);

  const onMouseDown = () => setIsDragging(true);
  const onMouseUp = () => setIsDragging(false);

  return (
    <div className="shopByConcern">
      <Swiper
        slidesPerView={window.innerWidth < 992 ? 2 : window.innerWidth < 1400 ? 4 : 6}
        spaceBetween={window.innerWidth < 992 ? 10 : 30}
        navigation={{ disabled: true }}
        pagination={{ clickable: true }}
        grabCursor={!isDragging} // Enable grab cursor only when not dragging
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        className="shopConcern"
      >
        <SwiperSlide>
          <Link to="/product-category/acne">
            <img
              className="w-[160px] h-[160px] lg:w-[180px] lg:h-[180px]"
              width={window.innerWidth < 992?160:180}
              height={window.innerWidth < 992?160:180}
              src={acne}
              alt="Acne"
            />
            <p className="text-center mt-6 text-2xl font-bold">Acne</p>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/product-category/haircare">
            <img
              className="w-[160px] h-[160px] lg:w-[180px] lg:h-[180px]"
              width={window.innerWidth < 992?160:180}
              height={window.innerWidth < 992?160:180}
              src={haircare}
              alt="Hair Care"
            />
            <p className="text-center mt-6 text-2xl font-bold">Haircare</p>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
        <Link to="/product-category/dullskin">
            <img
              className="w-[160px] h-[160px] lg:w-[180px] lg:h-[180px]"
              width={window.innerWidth < 992?160:180}
              height={window.innerWidth < 992?160:180}
              src={dullSKin}
              alt="Hair Care"
            />
            <p className="text-center mt-6 text-2xl font-bold">Dull Skin</p>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
        <Link to="/product-category/tan">
            <img
              className="w-[160px] h-[160px] lg:w-[180px] lg:h-[180px]"
              width={window.innerWidth < 992?160:180}
              height={window.innerWidth < 992?160:180}
              src={tan}
              alt="Hair Care"
            />
            <p className="text-center mt-6 text-2xl font-bold">Tanning</p>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
        <Link to="/product-category/oily">
            <img
              className="w-[160px] h-[160px] lg:w-[180px] lg:h-[180px]"
              width={window.innerWidth < 992?160:180}
              height={window.innerWidth < 992?160:180}
              src={oily}
              alt="Hair Care"
            />
            <p className="text-center mt-6 text-2xl font-bold">Oily Skin</p>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
        <Link to="/product-category/dry">
            <img
              className="w-[160px] h-[160px] lg:w-[180px] lg:h-[180px]"
              width={window.innerWidth < 992?160:180}
              height={window.innerWidth < 992?160:180}
              src={dry}
              alt="Dry skin Sloution"
            />
            <p className="text-center mt-6 text-2xl font-bold">Dry Skin</p>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
        <Link to="/product-category/aging">
            <img
              className="w-[160px] h-[160px] lg:w-[180px] lg:h-[180px]"
              width={window.innerWidth < 992?160:180}
              height={window.innerWidth < 992?160:180}
              src={aging}
              alt="Aging solution"
            />
            <p className="text-center mt-6 text-2xl font-bold">Aging</p>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
        <Link to="/product-category/spot">
            <img
              className="w-[160px] h-[160px] lg:w-[180px] lg:h-[180px] rounded-full"
              width={window.innerWidth < 992?160:180}
              height={window.innerWidth < 992?160:180}
              src={spot}
              alt="Hair Care"
            />
            <p className="text-center mt-6 text-2xl font-bold">Spot</p>
          </Link>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default ShopByConcern;
