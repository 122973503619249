import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";

const Filter = () => {
  const [pageCount, setPageCount] = useState(0);
  // const [page, setPage] = useState(localStorage.getItem("page"));
  const [page, setPage] = useState(0);
  const [products, setProducts] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [deletedId, setDeletedId] = useState();
  const [regPrice, setRegPrice] = useState();
  const [salePrice, setSalePrice] = useState();
  const [saleStatus, setSaleStatus] = useState();
  const [productStatus, setProductStatus] = useState(false);
  //   const [saleStartDate, setSaleStartDate] = useState(null);
  //   const [saleEndDate, setSaleEndDate] = useState(null);
  const [stockQuantity, setStockQuantity] = useState(0);
  const [stockStatus, setStockStatus] = useState();
  const [filterBy, setFilterBy] = useState("name");
  const [sku, setSku] = useState();
  const [metaTitle, setMetaTitle] = useState();
  const [metaDescription, setMetaDescription] = useState();

  useEffect(() => {
    fetch("https://ebay-backend.vercel.app/productCount")
      .then((res) => res.json())
      .then((data) => {
        const count = data.count;
        const pages = Math.ceil(count / 50);
        setPageCount(pages);
      });
  }, []);

  useEffect(() => {
    setProducts([]);
    setLoading(true);
    fetch(`https://ebay-backend.vercel.app/Allproducts?page=${page}`)
      .then((res) => res.json())
      .then((data) => {
        setProducts(data);
        setLoading(false);
      });
  }, [page]);

  const handleDelete = (id) => {
    fetch(`https://ebay-backend.vercel.app/deleteProduct/${id}`, {
      method: "delete",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
    toast.success("Product deleted sucessfully");
    const rest = products.filter((product) => product._id !== id);
    setProducts(rest);
    console.log(id, "deleted");
  };

  const handleSearchByTag = () => {
    setProducts([]);
    setLoading(true);
    fetch(`https://ebay-backend.vercel.app/filterByTags?name=${query}`)
      .then((res) => res.json())
      .then((data) => {
        setProducts(data);
        setLoading(false);
        setPageCount(0);
      });
  };
  const handleSearchByCategory = () => {
    setProducts([]);
    setLoading(true);
    fetch(`https://ebay-backend.vercel.app/filterByCategories?name=${query}`)
      .then((res) => res.json())
      .then((data) => {
        setProducts(data);
        setLoading(false);
        setPageCount(0);
      });
  };
  const handleSearchByName = () => {
    setProducts([]);
    setLoading(true);
    fetch(`https://ebay-backend.vercel.app/search/${query}`)
      .then((res) => res.json())
      .then((data) => {
        setProducts(data);
        setLoading(false);
        setPageCount(0);
      });
  };

  const handleUpdate = (product) => {
    const data = {
      status: productStatus || product.status,
      regular_price: regPrice || product.regular_price,
      sale_price: salePrice || product.sale_price,
      sku: sku || product.sku,
      meta_title : metaTitle || product.meta_title,
      // date_on_sale_from: saleStartDate,
      // date_on_sale_from_gmt: null,
      // date_on_sale_to: saleEndDate,
      // date_on_sale_to_gmt: null,
      on_sale: saleStatus ? JSON.parse(saleStatus) : product.on_sale,
      stock_quantity: stockQuantity || product.stock_quantity,
      stock_status: stockStatus || product.stock_status,
      meta_description: metaDescription || product.meta_description,
    };

    fetch(`https://ebay-backend.vercel.app/editProduct/${product._id}`, {
      method: "put",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
    toast.success("Product edited successfully");
  };

  return (
    <div>
      <div className="flex justify-between mx-12">
        <div className="flex gap-4 mt-12 items-center">
          <p>Filter by : </p>
          <select
            className="border border-secondary px-2 py-1"
            onChange={(e) => setFilterBy(e.target.value)}
          >
            <option>name</option>
            <option>Tag</option>
            <option>Category</option>
          </select>
        </div>
        <div>
          <input
            type="text"
            placeholder="Filter products"
            className="border border-secondary px-4 py-1 border-opacity-40 w-[300px]"
            onChange={(e) => setQuery(e.target.value)}
            value={query}
          ></input>
          <button
            onClick={
              filterBy === "Tag"
                ? handleSearchByTag
                : filterBy === "name"
                ? handleSearchByName
                : handleSearchByCategory
            }
            className="px-6 mt-12 ml-4 py-1 border border-accent bg-primary text-accent hover:bg-accent hover:text-primary ease-in-out duration-300 "
          >
            Filter
          </button>
        </div>
      </div>
      {/*.................. pagination ....................*/}
      {pageCount > 1 && (
        <div className="pages">
          {[...Array(pageCount).keys()].map((index) => (
            <button
              className={page == index ? "selected" : ""}
              onClick={() => {
                setPage(index);
                localStorage.setItem("page", index);
              }}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
      {/*.................. pagination ....................*/}
      {products.length < 1 && loading ? (
        <div className="flex justify-center items-center h-[90vh]">
          <ThreeDots
            height="100"
            width="100"
            radius="10"
            color="#7dc569"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="product-table">
            {/* head */}
            <thead className="bg-accent text-primary">
              <tr>
                <th></th>
                <th>Name</th>
                <th>Meta Title</th>
                <th>Meta Description</th>
                <th>Sale status</th>
                <th>Stock qty</th>
                <th>Stock status</th>
                <th>Reg price</th>
                <th>Sale price</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {/*................table row............... */}
              {products?.map((product) => (
                <tr className="">
                  <th>
                    <label>
                      <input type="checkbox" className="checkbox" />
                    </label>
                  </th>
                  <td>
                    <div className="flex items-center space-x-3">
                      <div className="avatar">
                        <div className="mask mask-squircle w-12 h-12">
                          <img src={product?.images[0]?.src} alt="" />
                        </div>
                      </div>
                      <div>
                        <Link to={`/admin/editProduct/${product._id}`}>
                          <p className="font-bold">{product?.name}</p>
                        </Link>
                      </div>
                    </div>
                  </td>
                  {/* <td>
                    <input
                      onChange={(e) => setSku(e.target.value)}
                      type="number"
                      className="border border-secondary px-2 w-[150px]"
                      placeholder={product?.sku}
                    />
                  </td> */}
                  <td>
                    <textarea
                      onChange={(e) => setMetaTitle(e.target.value)}
                      type="text"
                      className="border border-secondary px-2 w-[150px]"
                      placeholder={product?.meta_title}
                    />
                  </td>
                  <td>
                    <textarea
                      onChange={(e) => setMetaDescription(e.target.value)}
                      type="text"
                      className="border border-secondary px-2 w-[150px]"
                      placeholder={product?.meta_description}
                    />
                  </td>
                  <td>
                    <select
                      onChange={(e) => setSaleStatus(e.target.value)}
                      className="border border-secondary rounded "
                    >
                      {/* <option>{(product?.on_sale>0) ? "true" : "false"}</option>
                      <option>{!(product?.on_sale>0) ? "true" : "false"}</option> */}
                      <option>{JSON.stringify(product?.on_sale)}</option>
                      <option>true</option>
                      <option>false</option>
                    </select>
                  </td>
                  <td>
                    <input
                      onChange={(e) => setStockQuantity(e.target.value)}
                      type="number"
                      className="border border-secondary px-2 w-[80px]"
                      placeholder={product?.stock_quantity}
                    />
                  </td>
                  <td>
                    <select
                      onChange={(e) => setStockStatus(e.target.value)}
                      className="border border-secondary rounded "
                    >
                      <option>{product.stock_status}</option>
                      <option>instock</option>
                      <option>outofstock</option>
                    </select>
                  </td>
                  <td>
                    <input
                      onChange={(e) => setRegPrice(e.target.value)}
                      type="number"
                      className="border border-secondary px-2 w-[80px]"
                      placeholder={product?.regular_price}
                    />
                  </td>
                  <td>
                    <input
                      onChange={(e) => setSalePrice(e.target.value)}
                      type="number"
                      className="border border-secondary px-2 w-[80px]"
                      placeholder={product?.sale_price}
                    />
                  </td>
                  <td>
                    <select
                      onChange={(e) => setProductStatus(e.target.value)}
                      className="border border-secondary rounded"
                    >
                      <option>{product.status}</option>
                      <option>draft</option>
                      <option>publish</option>
                    </select>
                  </td>
                  <td>
                    <button
                      onClick={() => handleUpdate(product)}
                      className="mr-4 bg-accent text-primary px-2 hover:bg-secondary ease-in-out duration-200"
                    >
                      Save
                    </button>
                    <Link
                      onClick={() => {
                        setDeletedId(product._id);
                        document.getElementById("confirmation").showModal();
                      }}
                    >
                      <FontAwesomeIcon
                        className="text-[#b14444]"
                        icon={faTrash}
                      ></FontAwesomeIcon>
                    </Link>
                    <dialog
                      id="confirmation"
                      className="bg-accent rounded p-10"
                    >
                      <form method="dialog" className="">
                        <button className="btn text-primary btn-sm btn-circle btn-ghost absolute right-2 top-2">
                          ✕
                        </button>
                        <h3 className="font-bold text-lg text-primary">
                          Confirm Delete !
                        </h3>
                        <p className="py-4 text-primary">
                          Are you sure you want to delete this item ?
                        </p>
                        <button
                          onClick={() => handleDelete(deletedId)}
                          className="btn py-3 bg-primary text-accent border-none"
                        >
                          Confirm
                        </button>
                      </form>
                    </dialog>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* foot */}
            <tfoot className="bg-accent text-primary">
              <tr>
                <th></th>
                <th>Name</th>
                <th>Meta title</th>
                <th>Meta Description</th>
                <th>Sale status</th>
                <th>Stock qty</th>
                <th>Stock status</th>
                <th>Reg price</th>
                <th>Sale price</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </tfoot>
          </table>
        </div>
      )}

      {/*.................. pagination ....................*/}
      {pageCount > 1 && (
        <div className="pages mb-[100px]">
          {[...Array(pageCount).keys()].map((index) => (
            <button
              className={page == index ? "selected" : ""}
              onClick={() => setPage(index)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
      {/*.................. pagination ....................*/}
    </div>
  );
};

export default Filter;

//npm install react react-dom react-modal
