import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faList } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import Product from "../Components/Shared/Product";
import { Helmet } from "react-helmet-async";

const Shop = () => {
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { category } = useParams();
  const [list, setList] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [isOpen, setIsOpen] = useState(true);
  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState(false);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(1000);
  const [sortBy, setSortBY] = useState("Recommanded");

  useEffect(() => {
    fetch(`https://ebay-backend.vercel.app/shopProductCount`)
      .then((res) => res.json())
      .then((data) => {
        const count = data.count;
        const pages = Math.ceil(count / 50);
        setPageCount(pages);
      });
  }, [category]);

  useEffect(() => {
    setCategoryProducts([]);
    setLoading(true);
    fetch(`https://ebay-backend.vercel.app/shop?page=${page}`)
      .then((res) => res.json())
      .then((data) => {
        setCategoryProducts(data);
        setLoading(false);
      });
    window.scrollTo(0, 0);
  }, [category, page]);

  useEffect(() => {
    if (sortBy === "Price: Low to High") {
      const filtered = filterProductsByPriceLowToHigh(categoryProducts);
      setFilteredProducts(filtered);
    }
    if (sortBy === "Price: High to Low") {
      const filtered = filterProductsByPriceHighToLow(categoryProducts);
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
  }, [sortBy, categoryProducts]);

  const handleFilter = () => {
    const filtered = categoryProducts.filter(
      (product) => product.regular_price >= from && product.regular_price <= to
    );
    setFilteredProducts(filtered);
  };
  const handleReset = () => {
    setFilteredProducts([]);
  };
  const filterProductsByPriceLowToHigh = (products) => {
    products.sort((a, b) => a.price - b.price);
    return products;
  };
  const filterProductsByPriceHighToLow = (products) => {
    products.sort((a, b) => b.price - a.price);
    return products;
  };

  return (
    <div
      className={`lg:w-[90%] w-[95%] mx-auto mb-20 ${
        window.innerWidth >= 1920 ? "2xl:w-[65%]" : "2xl:w-[80%]"
      }`}
    >
      <Helmet>
        <title>Organic Natural Skincare Beauty Products Online </title>
        <meta
          name="description"
          content="Explore our shop for eco-friendly and organic beauty products. Find everything you need for natural skincare at Earth Beauty & You."
        />
        <link rel="canonical" href={`https://earthbeautyandyou.com/shop`} />
        <meta name="robots" content="INDEX, FOLLOW" />
        {/* Add JSON-LD structured data for the shop page */}
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "ItemList",
              "name": "Best skincare products in Bangladesh - Earth Beauty & You",
              "description": "Shop From Earth Beauty & You – best skincare products at the best price in Bangladesh. From skin care to hair care essentials, each product embodies the essence of nature. Explore organic face wash, hand wash, hair oil, lotion, shower gel and toner",
              "itemListElement": [
                ${categoryProducts
                  .map(
                    (product, index) => `
                  {
                    "@type": "ListItem",
                    "position": ${index + 1},
                    "url": "https://earthbeautyandyou.com/product/${
                      product?.slug
                    }"
                  }
                `
                  )
                  .join(",")}
              ]
            }
          `}
        </script>
      </Helmet>
      <div className="my-10 w-[90%] 2xl:w-[full lg:w-full mx-auto">
        <p className="text-[14px] font-semibold">
          Home
          <FontAwesomeIcon
            className="mx-2"
            icon={faCaretRight}
          ></FontAwesomeIcon>
          <h1 className="inline">Organic Natural Skincare Beauty Products Online</h1>
        </p>
      </div>
      <p
        className={`w-[90%] 2xl:w-full lg:w-full mx-auto ${
          category?.includes("top 10") ? "text-center" : ""
        } font-bold my-10 text-[22px]`}
      >
        {category?.toUpperCase()}
      </p>

      <div className="hidden lg:flex items-center mb-10">
        <button onClick={() => setList(false)}>
          <img
            width="25"
            height="25"
            src="https://img.icons8.com/sf-regular-filled/48/grid.png"
            alt="grid"
            className="inline mt-[-4px]"
          />
          Grid
        </button>
        <button onClick={() => setList(false)}>
          <FontAwesomeIcon className="mx-2" icon={faList}></FontAwesomeIcon>List
        </button>
      </div>

      {list ? (
        <div className="list-container grid gap-6 grid-cols-1">
          {categoryProducts?.map((product) => (
            <Product key={product._id} list={list} product={product}></Product>
          ))}
        </div>
      ) : (
        <div className="flex flex-col lg:flex-row gap-10">
          <div className="hidden lg:block lg:w-[25%] bg-[#E6F5F5] rounded-3xl p-6 shadow-lg shadow-secondary">
            <p className="mb-2 font-bold">SORT BY : </p>
            <select
              onChange={(e) => setSortBY(e.target.value)}
              className="px-4 py-2 rounded-lg font-bold"
            >
              <option>Recommanded</option>
              <option>New Launches</option>
              <option>Price: High to Low</option>
              <option>Price: Low to High</option>
            </select>
            <div className="w-[100%] mt-6 py-6 px-4 bg-primary rounded-lg sticky top-[300px]">
              <div className="bg-[#cccccc] h-[1px]"></div>
              <div className="flex justify-between items-center">
                <p className="text-sm my-2 font-bold">SHOP BY PRODUCT TYPE</p>
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="font-bold text-2xl"
                >
                  {isOpen ? "-" : "+"}
                </button>
              </div>
              <div className="bg-[#cccccc] h-[1px]"></div>
              <div
                className={`flex flex-col overflow-hidden ease-in-out duration-200 mt-4 ${
                  isOpen ? "h-fit-content" : "h-0"
                }`}
              >
                <Link
                  className="hover:text-accent ease-in-out duration-200"
                  to="/product-category/face wash"
                >
                  Face Wash
                </Link>
                <Link
                  className="hover:text-accent ease-in-out duration-200"
                  to="/product-category/lotion"
                >
                  Lotion
                </Link>
                <Link
                  className="hover:text-accent ease-in-out duration-200"
                  to="/product-category/mist toner"
                >
                  Mist Toner
                </Link>
                <Link
                  className="hover:text-accent ease-in-out duration-200"
                  to="/product-category/oil"
                >
                  Oil
                </Link>
                <Link
                  className="hover:text-accent ease-in-out duration-200"
                  to="/product-category/hand wash"
                >
                  Hand Wash
                </Link>
                <Link
                  className="hover:text-accent ease-in-out duration-200"
                  to="/product-category/moisturizer"
                >
                  Moisturizer
                </Link>
                <Link
                  className="hover:text-accent ease-in-out duration-200"
                  to="/product-category/sunscreen"
                >
                  Sunscreen
                </Link>
                <Link
                  className="hover:text-accent ease-in-out duration-200 mb-4"
                  to="/product-category/shower gel"
                >
                  Shower Gel
                </Link>
                <div className="bg-[#cccccc] h-[1px]"></div>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm my-2 font-bold">SHOP BY CONCERN</p>
                <button
                  onClick={() => setIsOpen1(!isOpen1)}
                  className="font-bold text-2xl"
                >
                  {isOpen1 ? "-" : "+"}
                </button>
              </div>
              <div className="bg-[#cccccc] h-[1px]"></div>
              <div
                className={`flex flex-col overflow-hidden ease-in-out duration-200 mt-4 ${
                  isOpen1 ? "h-fit-content" : "h-0"
                }`}
              >
                <Link
                  className="hover:text-accent ease-in-out duration-200"
                  to="/product-category/skin care"
                >
                  Skin Concerns
                </Link>
                <Link
                  className="hover:text-accent ease-in-out duration-200 mb-4"
                  to="/product-category/hair care"
                >
                  Hair Concerns
                </Link>
                <div className="bg-[#cccccc] h-[1px]"></div>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm my-2 font-bold">PRICE</p>
                <button
                  onClick={() => setIsOpen2(!isOpen2)}
                  className="font-bold text-2xl"
                >
                  {isOpen2 ? "-" : "+"}
                </button>
              </div>
              <div className="bg-[#cccccc] h-[1px]"></div>
              <div
                className={`flex flex-col overflow-hidden ease-in-out duration-200 mt-4 ${
                  isOpen2 ? "h-fit-content" : "h-0"
                }`}
              >
                <input
                  onChange={(e) => setFrom(e.target.value)}
                  placeholder="From"
                  type="number"
                />
                <input
                  onChange={(e) => setTo(e.target.value)}
                  placeholder="To"
                  type="number"
                />
                <button
                  onClick={handleFilter}
                  className="bg-accent text-primary font-bold px-4 py-1 rounded-lg hover:bg-secondary ease-in-out duration-200 mt-2"
                >
                  Filter
                </button>
                {filteredProducts.length > 0 && (
                  <button
                    onClick={handleReset}
                    className="text-center mx-auto mt-4 text-[#ff0000] hover:underline"
                  >
                    Reset
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-y-8 lg:gap-6 lg:grid-cols-2 xl:grid-cols-3 lg:w-[75%]">
            {loading ? (
              <div className="flex justify-center items-center h-[50vh] w-[100vw] lg:w-[55vw]">
                <ThreeDots
                  height="100"
                  width="100"
                  radius="10"
                  color="#abcacb"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              (filteredProducts.length > 0
                ? filteredProducts
                : categoryProducts
              )?.map((product) => (
                <Product key={product._id} product={product}></Product>
              ))
            )}
          </div>
        </div>
      )}
      {/*.................. pagination ....................*/}
      {pageCount > 1 && (
        <div className="pages mb-[100px]">
          {[...Array(pageCount).keys()].map((index) => (
            <button
              className={page == index ? "selected" : ""}
              onClick={() => setPage(index)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
      {/*.................. pagination ....................*/}
    </div>
  );
};

export default Shop;
