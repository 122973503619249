import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";

const AllBlog = () => {
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(localStorage.getItem("page"));
  const [blogs, setBlogs] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [loading, setLoading] = useState(false);
  const [deletedId, setDeletedId] = useState();

  useEffect(() => {
    fetch("https://ebay-backend.vercel.app/productCount")
      .then((res) => res.json())
      .then((data) => {
        const count = data.count;
        const pages = Math.ceil(count / 50);
        setPageCount(pages);
      });
  }, []);

  useEffect(() => {
    setBlogs([]);
    setLoading(true);
    fetch(`https://ebay-backend.vercel.app/getAllBlogs?page=${page}`)
      .then((res) => res.json())
      .then((data) => {
        setBlogs(data);
        setLoading(false);
      });
  }, [page]);

  const handleDelete = (id) => {
    fetch(`https://ebay-backend.vercel.app/deleteBlog/${id}`, {
      method: "delete",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
    toast.success("Product deleted sucessfully");
    const rest = blogs?.filter((product) => product?._id !== id);
    setBlogs(rest);
    console.log(id, "deleted");
  };

  const handleSearch = () => {
    setBlogs([]);
    setLoading(true);
    fetch(`https://ebay-backend.vercel.app/search/${searchedText}`)
      .then((res) => res.json())
      .then((data) => {
        setBlogs(data);
        setLoading(false);
        setPageCount(0);
      });
  };

  return (
    <div>
      <div className="flex justify-between mx-12">
        <div className="flex">
          <h1 className="mt-12 mr-12 text-2xl">Blogs</h1>
          <Link
            to="/admin/addBlog"
            className="px-6 mt-12 mr-6 border flex justify-center items-center border-accent bg-primary text-accent hover:bg-accent hover:text-primary ease-in-out duration-300 "
          >
            Add New
          </Link>
          {/* <Link
            to="/admin/filter"
            className="px-6 mt-12 border border-accent flex justify-center items-center bg-primary text-accent hover:bg-accent hover:text-primary ease-in-out duration-300 "
          >
            Bulk Edit
          </Link> */}
        </div>
        <div>
          <input
            type="text"
            placeholder="Search..."
            className="border border-secondary px-4 py-1 border-opacity-40"
            onChange={(e) => setSearchedText(e.target.value)}
            value={searchedText}
          ></input>
          <button
            onClick={handleSearch}
            className="px-6 mt-12 ml-4 py-1 border border-accent bg-primary text-accent hover:bg-accent hover:text-primary ease-in-out duration-300 "
          >
            Search
          </button>
        </div>
      </div>
      {/*.................. pagination ....................*/}
      {pageCount > 1 && (
        <div className="pages">
          {[...Array(pageCount).keys()].map((index) => (
            <button
              className={page == index ? "selected" : ""}
              onClick={() => {
                setPage(index);
                localStorage.setItem("page", index);
              }}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
      {/*.................. pagination ....................*/}
      {blogs?.length < 1 && loading ? (
        <div className="flex justify-center items-center h-[90vh]">
          <ThreeDots
            height="100"
            width="100"
            radius="10"
            color="#abcacb"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="product-table">
            {/* head */}
            <thead className="bg-accent text-primary">
              <tr>
                <th></th>
                <th>Title</th>
                <th>Author</th>
                <th>Status</th>
                <th>Categories</th>
                <th>Tags</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {/*................table row............... */}
              {blogs?.map((blog) => (
                <tr className="">
                  <th>
                    <label>
                      <input type="checkbox" className="checkbox" />
                    </label>
                  </th>
                  <td>
                    <p className="font-bold">{blog?.title?.rendered}</p>
                  </td>
                  <td>
                    <p>{blog?.yoast_head_json?.author}</p>
                  </td>
                  <td>
                    <p>{blog?.status}</p>
                  </td>

                  <td>
                    <p>Skin Care</p>
                  </td>
                  <td>
                    {blog?.tags.map((tag) => (
                      <p>{tag?.name} , </p>
                    ))}
                  </td>
                  <td>
                    <Link to={`/admin/editBlog/${blog?._id}`} className="mr-4">
                      <FontAwesomeIcon
                        className="text-[#8ed03a]"
                        icon={faPenToSquare}
                      ></FontAwesomeIcon>
                    </Link>
                    <Link
                      onClick={() => {
                        setDeletedId(blog?._id);
                        document.getElementById("confirmation").showModal();
                      }}
                    >
                      <FontAwesomeIcon
                        className="text-[#b14444]"
                        icon={faTrash}
                      ></FontAwesomeIcon>
                    </Link>
                    <dialog
                      id="confirmation"
                      className="bg-accent rounded p-10"
                    >
                      <form method="dialog" className="">
                        <button className="btn text-primary btn-sm btn-circle btn-ghost absolute right-2 top-2">
                          ✕
                        </button>
                        <h3 className="font-bold text-lg text-primary">
                          Confirm Delete !
                        </h3>
                        <p className="py-4 text-primary">
                          Are you sure you want to delete this item ?
                        </p>
                        <button
                          onClick={() => handleDelete(deletedId)}
                          className="btn py-3 bg-primary text-accent border-none"
                        >
                          Confirm
                        </button>
                      </form>
                    </dialog>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* foot */}
            <tfoot className="bg-accent text-primary">
              <tr>
                <th></th>
                <th>Title</th>
                <th>Author</th>
                <th>Status</th>
                <th>Categories</th>
                <th>Tags</th>
                <th>Actions</th>
              </tr>
            </tfoot>
          </table>
        </div>
      )}

      {/*.................. pagination ....................*/}
      {pageCount > 1 && (
        <div className="pages mb-[100px]">
          {[...Array(pageCount).keys()].map((index) => (
            <button
              className={page == index ? "selected" : ""}
              onClick={() => setPage(index)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
      {/*.................. pagination ....................*/}
    </div>
  );
};

export default AllBlog;

//npm install react react-dom react-modal
