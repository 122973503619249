// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyAKphmjvkMOIoAoPC9dddil6zxylsqnHzQ",
  authDomain: "ebay-web.firebaseapp.com",
  projectId: "ebay-web",
  storageBucket: "ebay-web.appspot.com",
  messagingSenderId: "719301892361",
  appId: "1:719301892361:web:62ba777ef0fa6071c41bd8"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default auth;

