import React, { useContext } from "react";
import "./Product.css";
import { ThemeContext } from "../../Contexts/ThemeContext";
import { toast } from "react-hot-toast";
import { addToDb } from "../../utilities/CartDb";
import {useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ReactPixel from 'react-facebook-pixel';


const Product = ({ product, list }) => {
  const { cart, setCart } = useContext(ThemeContext);
  const navigate = useNavigate();

  const handleAddToCart = (item) => {
    const exists = cart.find((product) => product._id === item._id);
    const newCart = exists
      ? cart.map((product) =>
          product._id === item._id ? { ...product, quantity: product.quantity + 1 } : product
        )
      : [...cart, { ...item, quantity: 1 }];
    setCart(newCart);
    addToDb(item._id);
    toast.success("ADDED TO CART");
    ReactPixel.track("AddToCart", {
      content_ids: item?._id,
      content_name: item?.name,
      content_type: "product",
      value:JSON.stringify(product?.on_sale) === "true"
          ? product?.sale_price
          : product?.regular_price,
      currency: "TAKA",
    });
  };

  return (
    <div className={`product relative shadow-2xl shadow-[#d1edec] ${list ? "py-6" : ""}`}>
      {product.on_sale && (
        <div className="bg-accent absolute w-[40px] h-[40px] left-2 top-6 z-50 flex justify-center items-center rounded-full sm:w-[50px] sm:h-[50px] sm:left-4 sm:top-4">
          <p style={{ color: "#fff", fontFamily: "Roboto-Regular" }}>
            -{Math.floor(((product.regular_price - product.sale_price) * 100) / product.regular_price)}%
          </p>
        </div>
      )}
      {product.stock_quantity < 1 && (
        <div className="bg-[#cccccc] absolute w-[50px] h-[50px] left-1 top-1 text-center z-50 flex justify-center items-center rounded-full sm:w-[50px] sm:h-[50px] sm:left-6 sm:top-3">
          <p style={{ color: "#fff", fontFamily: "Roboto-Regular" }}>Stock Out</p>
        </div>
      )}
      <div className="img-container cursor-pointer ease-in-out duration-200">
        <div
          onClick={() => navigate(`/product/${product.slug}`)}
          className="img-div rounded"
        >
          <LazyLoadImage
            width={window.innerWidth<720?180:250}
            height={window.innerWidth<720?150:250}
            src={product.images[0]?.src}
            alt={product.name}
            className="rounded"
            effect="blur"
          />
        </div>
      </div>
      <div className="product-details">
        <h2
          onClick={() => navigate(`/product/${product.slug}`)}
          className={`limit-lines text-center cursor-pointer font-bold ${window.innerWidth<720?"w-[150px]":"w-[250px]"} sm:px-[15px]`}
        >
          {product.name}
        </h2>
        {window.innerWidth > 640 && (
          <div className={`text-[#f3c621] flex ${list ? "" : "sm:justify-center"}`}>
          </div>
        )}

        <div className={`flex gap-2 ${list ? "" : "sm:justify-center"}`}>
          <p className="text-accent font-bold sm:text-lg">
            {product.on_sale ? product.sale_price : product.regular_price} BDT.
          </p>
          {product.on_sale && (
            <p
              style={{
                textDecorationLine: "line-through",
                textDecorationStyle: "solid",
                color: "#000",
                opacity: 0.5,
              }}
            >
              {`${product.regular_price} BDT.`}
            </p>
          )}
        </div>
        <div className="bg-accent flex justify-center items-center mt-4 w-[60px] h-[20px] mx-auto sm:pt-1 rounded-full">



          <p className="text-primary text-xs">
            {product.attributes[0]?.options}
          </p>
        </div>
        {product.stock_quantity > 0 ? (
          <button
            onClick={() => handleAddToCart(product)}
            className="add-btn mt-4 bold"
          >
            ADD TO CART
          </button>
        ) : (
          <button className="add-btn mt-4 text-[#cccccc] pointer-events-none">
            ADD TO CART
          </button>
        )}
      </div>
    </div>
  );
};

export default Product;
