import React from "react";
import { Helmet } from "react-helmet-async";
import logo from "../Images/Body-Soul-LLC.png";
const About = () => {
  return (
    <div className="p-[6%] sm:px-[10%] sm:py-[2%]">
      <Helmet>
        <title>{`Who We Are - Earth Beauty and You`}</title>
        <meta
          name="description"
          content="Learn about Earth Beauty and You. Discover our mission, values, and the team dedicated to enhancing your natural beauty"
        />
        <link rel="canonical" href={`https://earthbeautyandyou.com/about`} />
      </Helmet>
      <p className="text-4xl font-bold my-10 text-center">
        <span className="text-accent font-bold">Earth</span> Beauty &{" "}
        <span className="text-accent font-bold">You</span>
      </p>
      <h1 className="text-3xl text-secondary font-bold my-10 text-center sm:w-[600px] mx-auto">
        About Earth Beauty and You: Our Story and Mission
      </h1>
      <div className="flex flex-col lg:flex-row gap-10 items-center justify-center">
        <div className="w-[100%] lg:w-[50%]">
          {/* <p className="text-3xl text-center font-bold mb-6">
            About Earth Beauty & You Brand
          </p> */}
          {/* <img className="w-[100%] " src={img} alt="" /> */}

          <p className="font-bold text-[18px]">
            At Earth Beauty & You, we’re passionate about skin & hair care that
            is inspired by nature. We believe true beauty blossoms when you
            connect with nature. That's why we handcraft skincare using the
            earth's finest organic ingredients, empowering you to radiate
            naturally.
          </p>
          <p className="font-bold my-4 text-[18px]">
            <span className="text-accent font-bold mt-2 mb-4">
              Nourish Your Skin, Embrace Your Values:
            </span>
            <br></br>• Certified organic ingredients for a healthy glow.
            <br></br>• Cruelty-free & vegan products that align with your
            beliefs.<br></br>• Non-toxic formulas free from harmful chemicals.
          </p>
          {/* <img className="w-[100%] " src={img1} alt="" /> */}
          <p className="mb-4 font-bold text-[18px]">
            <span className="text-accent font-bold mt-4 mb-2">
              Discover your radiance:
            </span>
            <br></br>• Explore our diverse range for a complete, natural
            routine.<br></br>• Each product crafted with care, bringing you the
            best of nature.<br></br>
          </p>
          <p className="font-bold text-[18px]">
            <span className="text-accent font-bold mb-4">
              Be naturally you.{" "}
            </span>
            <br /> Earth Beauty & You invites you to embrace your unique
            radiance and celebrate the beauty that is authentically yours.
          </p>
          <p className="mt-10 font-bold">
            Under License
            <br />
            Body & Soul LLC
            <br />
            16192 Coastal Highway Lewes, Delaware 19958
          </p>
          <br />
          <img className="w-[180px]" alt="" src={logo} />
        </div>
      </div>
    </div>
  );
};

export default About;
